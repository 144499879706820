<template>
    <div class="monitors">
      <div class="body">
            <div class="container-fluid">
                <div class="row">
                    <div class="col modal-header d-block">
                        <h5>
                            <i class="header-icon" :class="'fas fa-user-cog'"></i>
                            Minha Conta Club
                        </h5>
                        <p class="subtitle">Gerencie suas informações</p>
                    </div>
                    <div class="col-10">
                        <div class="row py-3 d-flex justify-content-end">
                            <div class="col col-md-2 col-lg-2 filter-controls">
                                <cc-button :classes="'fixed secondary'" :icon="'fas fa-store'" :content="'Minhas Empresas'" @click="show_store_management_modal=true"/>
                            </div>
                            <div class="col col-md-2 col-lg-2 filter-controls">
                                <cc-button :classes="'fixed secondary'" :icon="'fas fa-users'" :content="'Meus Usuários'" @click="show_user_management_modal=true" />
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-12 p-4 shadow rounded">
                        <cc-loader v-show="loading" />
                        <ValidationObserver v-slot="{ handleSubmit }">
                            <form action="" v-show="!loading" v-if="client && client.user" @submit.prevent="handleSubmit(save)">
                                <div class="row py-2 d-flex justify-content-center">
                                    <div class="col-md-3 col-lg-4">
                                        <label>Nome:<span style="color:red !important">*</span></label>
                                        <cc-input type="text" v-model="client.cli_nome" readonly :classes="'text-uppercase'"></cc-input>
                                    </div>
                                    <div class="col-md-3 col-lg-5">
                                        <label>Email:<span style="color:red !important">*</span></label>
                                        <ValidationProvider rules="required" v-slot="v">
                                            <cc-input type="text" v-model.trim="client.cli_email"></cc-input>
                                            <span class="error">{{ v.errors[0] }}</span>
                                        </ValidationProvider>
                                    </div>
                                </div>

                                <div class="row py-2 d-flex justify-content-center">
                                    <div class="col-md-3 col-lg-4">
                                        <label>Contato:<span style="color:red !important">*</span></label>
                                        <cc-input type="text" v-model="client.cli_contato" readonly :classes="'text-uppercase'"></cc-input>
                                    </div>
                                    <div class="col-md-3 col-lg-5">
                                        <label>Telefone:<span style="color:red !important">*</span></label>
                                        <ValidationProvider rules="required" v-slot="v">
                                            <cc-input v-mask="'(##) #.####-####'" type="text" v-model="client.cli_telefone"></cc-input>
                                            <span class="error">{{ v.errors[0] }}</span>
                                        </ValidationProvider>
                                    </div>
                                </div>

                                <div class="row py-2 d-flex justify-content-center">
                                    <div class="col-md-3 col-lg-4">
                                        <label>Nome Fantasia:<span style="color:red !important">*</span></label>
                                        <cc-input :classes="'text-uppercase'" type="text" v-model="client.cli_nomeFantasia" readonly></cc-input>
                                    </div>
                                    <div class="col-md-3 col-lg-5">
                                        <label>Nome Grupo:<span style="color:red !important">*</span></label>
                                        <cc-input type="text" v-model="client.cli_nome_grupo" readonly :classes="'text-uppercase'"></cc-input>
                                    </div>
                                </div>
                                <div class="row py-2 d-flex justify-content-center">
                                    <div class="col-md-3 col-lg-4">
                                        <label>CNPJ:<span style="color:red !important">*</span></label>
                                        <cc-input type="text" v-mask="'## ### ###/####-##'" v-model="client.cli_cnpj" readonly></cc-input>
                                    </div>
                                    <div class="col-md-3 col-lg-5">
                                        <label>Website:<span style="color:red !important">*</span></label>
                                        <cc-input type="text" v-model="client.cli_site" readonly></cc-input>
                                    </div>
                                </div>

                                <div class="row pt-2">
                                    <div v-if="message" class="col-md-12 col-lg-12">
                                        <h4 class="py-4 text-center text-danger">{{message}}</h4>
                                    </div>
                                    <div class="col d-flex justify-content-center">
                                        <button class="btnFeedbackModal" type="submit">Salvar</button>
                                    </div>
                                </div>
                            </form>
                        </ValidationObserver>
                    </div>
                </div>
            </div>
        </div>
        <user-management-modal :client="client" v-if="show_user_management_modal" @close="show_user_management_modal=false"></user-management-modal>
        <store-management-modal :client="client" v-if="show_store_management_modal" @close="show_store_management_modal=false"></store-management-modal>
    </div>
</template>
<script>

import AuthService from '@/services/AuthService'
import ClientService from "@/services/v2/client.service";
import ErrorHandlerService from "@/services/ErrorHandlerService";
import UserManagementModal from "@/modals/client/users/management.modal";
import StoreManagementModal from "@/modals/client/stores/management.modal";
import { loaderMixin } from '@/mixins/sweet-loader.mixin'
import { ValidationProvider, ValidationObserver } from 'vee-validate';

export default {
    mixins: [ loaderMixin ],
    components: {
        ValidationObserver,
        ValidationProvider,
        'user-management-modal': UserManagementModal,
        'store-management-modal': StoreManagementModal
    },
    data(){
        return{
          image:"/images/search.png",
            loading: false,
            client: {},
            stores: [],
            profiles: [],
            message: null,
            show_user_management_modal: false,
            show_store_management_modal: false,
            service: new AuthService(),
            svc: new ClientService(),
            breadcrumbs: [
                {
                    name: "Minhas Configurações",
                    route: 'settings'
                }
            ]
        }
    },
    methods: {
        save() {
            this.loading = true
            this.svc.update_client(this.client).then((response) => this.loading = false)
        }
    },
    created() {
        this.loading = true
        this.client = this.service.get_user().then(response => response.data).then(({ usuario }) => {
            this.client = usuario.cliente
            this.client.user = usuario
            delete this.client.user.cliente
            this.loading = false
        }, err => {
            this.loading = false;
            ErrorHandlerService.handle(err, this.$store, this.$store);
        })
        this.$store.dispatch('set_breadcrumbs', this.breadcrumbs);
    }
}
</script>

<style lang="scss" scoped>
    @import '@/sass/commons/_variables';

    .error {
        color: $lower-red;
    }

    .btnFeedbackModal {
        border-radius: 5px;
        box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.16);
        border: solid 1px $blue1;
        background-color: $secondary-color;
        font-family: "Source Sans Pro", sans-serif;
        font-size: 15px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.07;
        letter-spacing: normal;
        text-align: left;
        color: #ffffff;
        padding: 15px
    }

    .modal-header h3,.modal-header h4, .modal-header h5 {
        margin-top: 0;
        font-weight: bolder;
        font-size: 1.15rem;
        color: $secondary-color;
        text-transform: uppercase;
    }

    .modal-header {
        border: none;
        .subtitle {
            box-shadow: inset 7px 0px 0px 0px rgba(66,103,178,1);
            padding-left: 1rem;
            color: #314b5f;
            font-size: 0.9rem;
            font-family: Helvetica,sans-serif !important;
            font-weight: lighter;
        }
    }
</style>
